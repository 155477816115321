import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TemplateMessageObject } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Channel, Department, DepartmentService, MetadataMessage, Tag, TagService, Team, TeamService, Traduct, User, WebhookIntegrationItemType, WebhookIntegrations } from 'lib-trend-core';

@Component({
  selector: 'santri-integrations-item-component',
  templateUrl: './santri-integrations-item.component.html',
  styleUrls: ['./santri-integrations-item.component.scss'],
})
export class SantriIntegrationsItemComponent extends AbstractComponent implements OnInit {

  @Input() itemForm!: FormGroup;
  @Input() index!: number;
  @Input() webhookRequests: object;
  @Input() channel!: Channel;
  @Input() isActive = false;
  @Input() webhookIntegrations: WebhookIntegrations;
  @Output() remove = new EventEmitter<number>();

  selectedTemplate: TemplateMessageObject;

  showPreviewTemplate: boolean = false;

  showContainerEmoji: boolean = false;
  translator!: Traduct;

  tags: Tag[] = [];
  departments: Department[] = [];
  users: User[] = [];

  @ViewChild('messageTextarea') messageTextarea: ElementRef;
  @ViewChild("emojiContainer", { static: false, read: ElementRef }) emojiContainer!: ElementRef

  private clearTimeout
  private clickOutsideListener

  constructor(
    injector: Injector,
    public departmentService: DepartmentService,
    public tagService: TagService,
    public teamService: TeamService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super(injector)
    this.loadTags()
    this.loadDepartments();
  }

  ngOnInit(): void {
    if (this.itemForm.get('type').value === WebhookIntegrationItemType.START_ATTENDANCE) {
      this.loadUsersByDepartment();
    }
    if (this.itemForm.get('type').value === WebhookIntegrationItemType.ADD_TEMPLATE) {
      this.selectedTemplate = this.itemForm.get('template').value;
      if (this.selectedTemplate) {
        this.showPreviewTemplate = true;
      } else {
        this.showPreviewTemplate = false;
      }
    }
  }

  addValue(value: string): void {
    let valueForm = this.itemForm.get('value').value;
    if (!valueForm) {
      valueForm = '';
    }
    valueForm += '{{' + value + '}}';
    this.itemForm.get('value').setValue(valueForm);
  }

  deleteComponent(): void {
    this.remove.emit(this.index);
  }

  onSelectTemplate(template: TemplateMessageObject) {
    this.selectedTemplate = template
    this.showPreviewTemplate = true
    this.itemForm.get('template').setValue(this.selectedTemplate)
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      const textarea = event.target as HTMLTextAreaElement;
      const cursorPosition = textarea.selectionStart;
      let value = this.itemForm.get('value').value;
      const textBeforeCursor = value.slice(0, cursorPosition);

      if (textBeforeCursor.endsWith('}}')) {
        event.preventDefault();

        const startIndex = textBeforeCursor.lastIndexOf('{{');

        if (startIndex !== -1) {
          this.itemForm.get('value').setValue(
            textBeforeCursor.slice(0, startIndex) +
            value.slice(cursorPosition));
        }
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    pastedData = pastedData
      .replace(/<p[^>]*>/g, '\n')
      .replace(/<\/p>/g, '\n\n')
      .replace(/<li[^>]*>/g, '')
      .replace(/<\/li>/g, '\n')
      .replace(/<\/?html[^>]*>/g, '')
      .replace(/<\/?body[^>]*>/g, '')
      .replace(/<!--.*?-->/g, '')
      .replace(/<\/?br[^>]*>/g, '\n')
      .replace(/<\/?strong[^>]*>/g, '*')
      .replace(/<\/?b[^>]*>/g, '*')
      .replace(/<\/?em[^>]*>/g, '_')
      .replace(/<\/?i[^>]*>/g, '_')
      .replace(/<\/?u[^>]*>/g, '~$&~');

    pastedData = pastedData.replace(/\n{2,}/g, '\n\n').trim();

    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    textarea.value = textarea.value.substring(0, start) + pastedData + textarea.value.substring(end);

    textarea.setSelectionRange(start + pastedData.length, start + pastedData.length);
    textarea.focus();
    this.updateMessage();
  }

  loadTags(): void {
    this.tagService.getList().subscribe((tags) => {
      this.tags = tags;
    });
  }

  loadDepartments(): void {
    if (this.isAdmin()) {
      this.departmentService.getList().subscribe((departments) => {
        this.departments = departments;
      });
    } else {
      this.searchParams = {
        company: this.getIDCurrentCompany(),
        user: this.getIDCurrentUser()
      };
      this.teamService.getDepartmentFromSupervisor(0, 50, this.searchString, this.searchParams).subscribe((departments) => {
        this.departments = departments.list;
      });
    }
  }

  loadUsersByDepartment() {
    const idDepartment = this.itemForm.get('value').value;
    if (idDepartment) {
      this.teamService.getByDepartment(idDepartment).subscribe({
        next: (teams: Team[]) => {
          this.users = teams.map(({ agents }) => agents).flat();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    } else {
      this.users = [];
    }
  }

  applyFormat(format: string) {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);

    if (!selectedText) return;

    let formattedText = '';

    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`;
        break;
      case 'italic':
        formattedText = `_${selectedText}_`;
        break;
      case 'underline':
        formattedText = `~${selectedText}~`;
        break;
      default:
        return;
    }

    textarea.value =
      textarea.value.substring(0, start) +
      formattedText +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
    textarea.focus();
    this.updateMessage();
  }

  updateMessage() {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const messageWithoutLineBreaks = textarea.value;
    this.itemForm.patchValue({ value: messageWithoutLineBreaks });
  }

  toogleContainerEmoji(): void {
    clearTimeout(this.clearTimeout)

    this.showContainerEmoji = !this.showContainerEmoji;

    this.clearTimeout = setTimeout(() => {
      this.addClickOutsideListener();
    })
  }

  addClickOutsideListener() {
    this.clickOutsideListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (this.showContainerEmoji && this.emojiContainer) {
        const modalElement = this.emojiContainer.nativeElement as HTMLElement;
        const clickedInside = modalElement?.contains(event.target as Node);

        if (!clickedInside) {
          this.closedContainerEmoji();
        }
      }
    });
  }

  closedContainerEmoji(): void {
    this.showContainerEmoji = false;
    this.clickOutsideListener()
    this.clickOutsideListener = null
  }

  addEmoji(event: any): void {
    let message = this.itemForm.get('value').value;
    if (!message) {
      message = '';
    }
    message += event.emoji.native;
    this.itemForm.get('value').setValue(message);
  }

  insertRequestOnMessage(request) {
    const value = this.itemForm.get('value').value;
    if (value) {
      this.itemForm.get('value').setValue(value + ' {{' + request + '}}');
    } else {
      this.itemForm.get('value').setValue('{{' + request + '}}');
    }
  }

  setTemplateMessage(templateMessage: MetadataMessage) {
    this.itemForm.get('template').setValue(templateMessage.template);
    this.itemForm.get('templateParamsHeader').setValue(templateMessage.headerParams);
    this.itemForm.get('templateParamsBody').setValue(templateMessage.bodyParams);
    this.itemForm.get('previewText').setValue(templateMessage.previewText);
  }

  changeTemplate() {
    this.showPreviewTemplate = false;
    this.itemForm.get('template').setValue(null);
    this.itemForm.get('templateParamsHeader').setValue(null);
    this.itemForm.get('templateParamsBody').setValue(null);
    this.itemForm.get('previewText').setValue(null);
  }

  isRequired(): boolean {
    return this.itemForm.get('required').value === true;
  }

  isWhatsappPhone(): boolean {
    return this.itemForm.get('type').value === WebhookIntegrationItemType.WHATSAPP_PHONE;
  }

  isContactName(): boolean {
    return this.itemForm.get('type').value === WebhookIntegrationItemType.CONTACT_NAME;
  }

  isAddTag(): boolean {
    return this.itemForm.get('type').value === WebhookIntegrationItemType.ADD_TAG;
  }

  isStartAttendance(): boolean {
    return this.itemForm.get('type').value === WebhookIntegrationItemType.START_ATTENDANCE;
  }

  isRemoveTag(): boolean {
    return this.itemForm.get('type').value === WebhookIntegrationItemType.REMOVE_TAG;
  }

  isSendMessage(): boolean {
    return this.itemForm.get('type').value === WebhookIntegrationItemType.SEND_MESSAGE;
  }

  isAddTemplate(): boolean {
    return this.itemForm.get('type').value === WebhookIntegrationItemType.ADD_TEMPLATE;
  }

  getLabel(): string {
    return this.itemForm.get('label').value;
  }

  getMessage(): string {
    return this.itemForm.get('message').value;
  }

  isTypeMessageMedia(): boolean {
    return this.itemForm.get('typeMessage').value && this.itemForm.get('typeMessage').value !== 'text';
  }
}
