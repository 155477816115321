import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntegrationsComponent } from './integrations.component';
import { OpenAIAssistantsComponent } from './openai-integration/openai-integration-assistants/openai-integration-assistants.component';
import { OpenAIFormComponent } from './openai-integration/openai-integration-form/openai-integration-form.component';
import { OpenAIListComponent } from './openai-integration/openai-integration-list/openai-integration-list.component';
import { PipedriveFormComponent } from './pipedrive-integrations/pipedrive-integration-form/pipedrive-integration-form.component';
import { PipedriveListComponent } from './pipedrive-integrations/pipedrive-integration-list/pipedrive-integration-list.component';
import { SantriIntegrationsFormComponent } from './santri-integrations/santri-integrations-form/santri-integrations-form.component';
import { SantriIntegrationsListComponent } from './santri-integrations/santri-integrations-list/santri-integrations-list.component';
import { WebhookIntegrationsFormComponent } from './webhook-integrations/webhook-integrations-form/webhook-integrations-form.component';
import { WebhookIntegrationsListComponent } from './webhook-integrations/webhook-integrations-list/webhook-integrations-list.component';

const routes: Routes = [
  { path: '', component: IntegrationsComponent },

  { path: 'webhook/list', component: WebhookIntegrationsListComponent },
  { path: 'webhook/create', component: WebhookIntegrationsFormComponent },
  { path: 'webhook/:id/edit', component: WebhookIntegrationsFormComponent },

  { path: 'openai/list', component: OpenAIListComponent },
  { path: 'openai/create', component: OpenAIFormComponent },
  { path: 'openai/:id/edit', component: OpenAIFormComponent },
  { path: 'openai/assistants/:id', component: OpenAIAssistantsComponent },

  { path: 'pipedrive/list', component: PipedriveListComponent },
  { path: 'pipedrive/create', component: PipedriveFormComponent },
  { path: 'pipedrive/:id/edit', component: PipedriveFormComponent },

  { path: 'santri/list', component: SantriIntegrationsListComponent },
  { path: 'santri/create', component: SantriIntegrationsFormComponent },
  { path: 'santri/:id/edit', component: SantriIntegrationsFormComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationsRoutingModule { }
