import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Integration } from 'lib-trend-core';
import { AssistantListRequest, AssistantObject, TrendOpenAIAPIService } from '@trendbuild/trend-openai-api';

@Component({
  selector: 'app-assistant-create',
  templateUrl: './assistant-create.component.html',
  styleUrls: ['./assistant-create.component.scss']
})
export class AssistantCreateComponent extends AbstractComponent implements OnInit {

  private trendOpenAIAPIService: TrendOpenAIAPIService = new TrendOpenAIAPIService();
  availableModels = ['gpt-4', 'gpt-3.5-turbo'];
  assistantId: string | null = null;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<AssistantCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      integration: Integration,
      assistant?: AssistantObject,
      mode?: string
    }
  ) {
    super(injector);
    this.setupForm();

    this.isNew = !this.data.assistant;

    if (this.data.assistant) {
      this.assistantId = this.data.assistant.id;
      this.populateForm(this.data.assistant);
    }
  }

  ngOnInit() {
    if (this.data.mode === 'delete') {
      this.setupDeleteMode();
    }
  }

  setupForm() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      model: new FormControl('gpt-4', [Validators.required]),
      description: new FormControl(''),
      instructions: new FormControl('', [Validators.required]),
      tools: new FormGroup({
        code_interpreter: new FormControl(false),
        retrieval: new FormControl(false),
        function_calling: new FormControl(false)
      })
    });
  }

  populateForm(assistant: AssistantObject) {
    this.formGroup.patchValue({
      name: assistant.name || '',
      model: assistant.model,
      description: assistant.description || '',
      instructions: assistant.instructions || '',
      tools: {
        code_interpreter: assistant.tools.some(tool => tool['type'] === 'code_interpreter'),
        retrieval: assistant.tools.some(tool => tool['type'] === 'retrieval'),
        function_calling: assistant.tools.some(tool => tool['type'] === 'function_calling')
      }
    });
  }

  setupDeleteMode() {
    this.formGroup.disable();
  }

  close() {
    this.dialogRef.close();
  }

  async onSubmit() {
    if (this.data.mode === 'delete') {
      this.loading = true;
      try {
        await this.trendOpenAIAPIService.deleteAssistant({
          apiKey: this.data.integration.metadata['token'],
          organization: this.data.integration.metadata['organization'],
          project: this.data.integration.metadata['project'],
          assistant_id: this.data.assistant!.id
        });
        this.alertService.success('Assistente excluído com sucesso!');
        this.dialogRef.close(true);
      } catch (error: any) {
        this.alertService.error(error.error?.message || 'Erro ao excluir assistente');
        this.loading = false;
      }
      return;
    }

    if (this.formGroup.valid && this.data.integration) {
      this.loading = true;

      const formValue = this.formGroup.value;
      const tools = [];

      if (formValue.tools.code_interpreter) tools.push({ type: 'code_interpreter' });
      if (formValue.tools.retrieval) tools.push({ type: 'retrieval' });
      if (formValue.tools.function_calling) tools.push({ type: 'function_calling' });

      try {
        if (this.isNew) {
          await this.trendOpenAIAPIService.createAssistant({
            apiKey: this.data.integration.metadata['token'],
            organization: this.data.integration.metadata['organization'],
            project: this.data.integration.metadata['project'],
            model: formValue.model,
            name: formValue.name,
            description: formValue.description,
            instructions: formValue.instructions,
            tools: tools
          });
          this.alertService.success('Assistente criado com sucesso!');
          this.dialogRef.close(true);
        } else {
          await this.trendOpenAIAPIService.updateAssistant({
            apiKey: this.data.integration.metadata['token'],
            organization: this.data.integration.metadata['organization'],
            project: this.data.integration.metadata['project'],
            assistant_id: this.data.assistant!.id,
            model: formValue.model,
            name: formValue.name,
            description: formValue.description,
            instructions: formValue.instructions,
            tools: tools
          });
          this.alertService.success('Assistente atualizado com sucesso!');
          this.dialogRef.close(true);
        }
      } catch (error: any) {
        console.error('Error in onSubmit:', error);
        this.alertService.error(error.error?.message || 'Erro ao salvar assistente');
        this.loading = false;
      }
    }
  }
}
