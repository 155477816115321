"use strict";
/**
 * Documentation
 * https://platform.openai.com/docs/api-reference/introduction
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.openAIProviders = void 0;
exports.openAIProviders = {
    OPENAI_API_URL: 'https://api.openai.com/v1',
};
