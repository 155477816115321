import { Component, Injector, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { AbstractComponent, ConfirmationComponent, Pager, WebhookIntegrations, WebhookIntegrationsService, } from 'lib-trend-core'
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs'

@Component({
  selector: 'webhook-integrations-list-component',
  templateUrl: 'webhook-integrations-list.component.html',
})
export class WebhookIntegrationsListComponent extends AbstractComponent implements OnInit {

  pager: Pager<WebhookIntegrations> = new Pager<WebhookIntegrations>({ perPage: 10 });

  listObservable: Observable<Pager<WebhookIntegrations>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  constructor(
    public injector: Injector,
    private dialog: MatDialog,
    private webhookIntegrationsService: WebhookIntegrationsService
  ) {
    super(injector);
    this.loadingContent = false;
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: 'TRENDMULTI'
    };
    this.webhookIntegrationsService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .subscribe({
        next: (pager: Pager<WebhookIntegrations>) => {
          this.pager = pager;
          this.setupObservableSearch();
        },
        complete: () => (this.loadingContent = false),
      });
  }

  setupObservableSearch() {
    this.listObservable = this.termOfSearch
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((term) =>
          this.webhookIntegrationsService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams)
        )
      );
    this.listObservable.subscribe((pager: Pager<WebhookIntegrations>) => {
      this.pager = pager;
    });
  }

  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  showNewWebhook() {
    this.router.navigate(['/integrations/webhook/create'], { relativeTo: this.route, });
  }

  showEditWebhook(id) {
    this.router.navigate([`/integrations/webhook/${id}/edit`], { relativeTo: this.route, });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  delete(webhookId: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result) === true) {
        this.webhookIntegrationsService.delete(webhookId).subscribe({
          next: (value) => {
            this.getList()
            this.alertService.success()
          },
          error: (err) => this.alertService.error(err.error.message),
        })
      }
    });
  }

}
