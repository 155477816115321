import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PickerModule } from '@ctrl/ngx-emoji-mart'
import { SharedModule } from 'lib-trend-core'
import { WaTemplateModule } from '../wa-template/webhook-integration-wa-template/webhook-integrations-wa-template.module'
import { IntegrationsRoutingModule } from './integrations-routing.module'
import { IntegrationsComponent } from './integrations.component'
import { AssistantCreateComponent } from './openai-integration/openai-integration-assistants/assistant-create/assistant-create.component'
import { OpenAIAssistantsComponent } from './openai-integration/openai-integration-assistants/openai-integration-assistants.component'
import { OpenAIFormComponent } from './openai-integration/openai-integration-form/openai-integration-form.component'
import { OpenAIListComponent } from './openai-integration/openai-integration-list/openai-integration-list.component'
import { PipedriveFormComponent } from './pipedrive-integrations/pipedrive-integration-form/pipedrive-integration-form.component'
import { PipedriveListComponent } from './pipedrive-integrations/pipedrive-integration-list/pipedrive-integration-list.component'
import { SantriIntegrationsItemComponent } from './santri-integrations/santri-integration-item/santri-integrations-item.component'
import { SantriIntegrationsFormComponent } from './santri-integrations/santri-integrations-form/santri-integrations-form.component'
import { SantriIntegrationsListComponent } from './santri-integrations/santri-integrations-list/santri-integrations-list.component'
import { WebhookIntegrationsItemComponent } from './webhook-integrations/webhook-integration-item/webhook-integrations-item.component'
import { WebhookIntegrationsFormComponent } from './webhook-integrations/webhook-integrations-form/webhook-integrations-form.component'
import { WebhookIntegrationsListComponent } from './webhook-integrations/webhook-integrations-list/webhook-integrations-list.component'

@NgModule({
  imports: [
    IntegrationsRoutingModule,
    WaTemplateModule,
    PickerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [
    IntegrationsComponent,
    WebhookIntegrationsListComponent,
    WebhookIntegrationsFormComponent,
    WebhookIntegrationsItemComponent,
    OpenAIListComponent,
    OpenAIFormComponent,
    OpenAIAssistantsComponent,
    AssistantCreateComponent,
    PipedriveFormComponent,
    PipedriveListComponent,
    SantriIntegrationsItemComponent,
    SantriIntegrationsFormComponent,
    SantriIntegrationsListComponent
  ],
  providers: [
  ]
})
export class IntegrationsModule { }
