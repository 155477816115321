"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreadService = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class ThreadService extends base_service_1.RestService {
    constructor() {
        super();
    }
    createThreadRun(createThreadRunRequest) {
        const url = this.getBaseURL(`threads/runs`);
        const { apiKey } = createThreadRunRequest, newBody = tslib_1.__rest(createThreadRunRequest, ["apiKey"]);
        return super.post(url, newBody, this.headerConfig(createThreadRunRequest));
    }
    createRun(createRunRequest) {
        const url = this.getBaseURL(`threads/${createRunRequest.thread_id}/runs`);
        const { apiKey, thread_id } = createRunRequest, newBody = tslib_1.__rest(createRunRequest, ["apiKey", "thread_id"]);
        return super.post(url, newBody, this.headerConfig(createRunRequest));
    }
    retrieveRun(retrieveRunRequest) {
        const url = this.getBaseURL(`threads/${retrieveRunRequest.thread_id}/runs/${retrieveRunRequest.run_id}`);
        return super.get(url, this.headerConfig(retrieveRunRequest));
    }
    listRunSteps(listRunStepRequest) {
        const url = this.getBaseURL(`threads/${listRunStepRequest.thread_id}/runs/${listRunStepRequest.run_id}/steps`);
        return super.get(url, this.headerConfig(listRunStepRequest));
    }
    retrieveMessage(retrieveMessageRequest) {
        const url = this.getBaseURL(`threads/${retrieveMessageRequest.thread_id}/messages/${retrieveMessageRequest.message_id}`);
        return super.get(url, this.headerConfig(retrieveMessageRequest));
    }
}
exports.ThreadService = ThreadService;
