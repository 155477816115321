"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelService = void 0;
const base_service_1 = require("./base.service");
class ModelService extends base_service_1.RestService {
    constructor() {
        super();
    }
    listModels(baseRequest) {
        const url = this.getBaseURL(`models`);
        return super.get(url, this.headerConfig(baseRequest));
    }
    getModelById(modelRetrievesRequest) {
        const url = this.getBaseURL(`models/${modelRetrievesRequest.id}`);
        return super.get(url, this.headerConfig(modelRetrievesRequest));
    }
}
exports.ModelService = ModelService;
