<div class="flex flex-1 flex-col px-5">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Integrações OpenAI</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Organize seus departamentos conforme seu processo de atendimento</h6>
    <hr />
  </div>

  <div class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px]">
    <div class="inline-block min-w-full py-2 align-middle">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-gray-100 border-2">

        <div class="flex justify-between items-center p-4">
          <h2 class="text-xl font-semibold">Assistentes Integrados</h2>
          <button mat-raised-button color="primary" (click)="openCreateAssistantModal()">Adicionar Assistente</button>
        </div>

        <table class="min-w-full divide-gray-100 divide-y-[3px]">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500" style="width: 15%;">Data de Criação</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500" style="width: 30%;">Nome do Assistente</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500" style="width: 35%;">Selecionar Canal</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500" style="width: 20%;">Ações</th>
            </tr>
          </thead>
          <tbody class="divide-gray-100 divide-y-[3px] bg-white">
            <tr *ngFor="let assistantObj of assistants" class="rounded-none cursor-pointer">
              <td class="whitespace-nowrap px-3 py-4">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ assistantObj.assistant.created_at * 1000 | date: 'dd/MM/yyyy' }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                <div class="font-weight-600">{{ assistantObj.assistant.name || 'Sem Nome' }}</div>
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                <mat-select class="input-field" style="max-width: 200px; width: 100%;"
                            [(ngModel)]="assistantObj.selectedChannel"
                            placeholder="Selecione"
                            (selectionChange)="toggleAssistant(assistantObj.assistant.id)"
                            multiple>
                  <mat-option *ngFor="let channel of channels" [value]="channel._id">{{ channel.name }}</mat-option>
                </mat-select>
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                <button mat-raised-button color="primary" (click)="openEditAssistantModal(assistantObj)">
                  Editar
                </button>
                <button mat-raised-button color="warn" (click)="deleteAssistant(assistantObj)">
                  Deletar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
